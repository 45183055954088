.Nav-item{
  padding: 1em;
  color: black;
  font-size: 1em;
  background-color: white;
  text-align: center;
  font-size: large;
  font-weight: 2000;
  top: 0;
}

.Nav-bar{
  background-color: white;
  width: 100%;
  position: fixed;
  overflow: hidden;
  transition: 1s;
}

.Nav-item:hover{
  background-color: black;
  color: white;
  transition: .5s;
}

.scrolled{
  background-color: white;
  position: fixed;
  width: 100%;
  transition: .5s;
}

.item-scrolled{
  padding: .5em;
  color: black;
  font-size: .5em;
  background-color: white;
  text-align: center;
  font-size: medium;
  font-weight: 200;
  width: 20%;
  transition: .5s;
}

@media(max-width: 767px){
  .Nav-item{
    font-size: .8em;
  }
}