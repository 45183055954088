.App {
  text-align: center;
  overflow: hidden;
}

.App-header {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
  width: auto;
  padding: 1.75em;
  text-align: center;
}

section {
  color: white;
  text-align: left;
  font-size: 2em;
  padding: 1.75em;
  min-height: 75vh;
  border: 4em solid transparent;
  width: 80%;
  font-family: Tahoma, sans-serif;
  height: auto;
  width: auto;
}

section a {
  color: #aaaaaa;
}

.App-footer {
  color: white;
  font-size: 2em;
  padding: 2em;
  text-align: center;
  font-weight: 30;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-shrink:hover, .hvr-shrink:focus, .hvr-shrink:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.panel{
  scroll-behavior: smooth;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: -1;
  left: 0;
  background: rgb(13,17,68);
  background: linear-gradient(190deg, rgba(13,17,68,1) 0%, rgba(5,6,13,1) 100%);
}

@media (max-width: 767px){
  section{
    font-size: .9em;
    padding: .5em;
    width: auto;
    height: auto;
  }

  .App-header{
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    width: auto;
    padding: 2em;
    text-align: center;
  }

  .background{
    height: 100%;
    width: 100%;
  }
}